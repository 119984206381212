$color-primary-blue: #0e2673;
$color-primary-orange: #f35d5b;
$color-deep-orange: #ba4341;
$color-dark-slate-blue: #354989;
$color-ultramarine-blue: #3275f4;
$color-dark-pastel-blue: #7898d5;
$color-glitter: #e6e9f1;
$color-pastel-orange: #f8ad4b;
$color-black: #000;
$color-dark: #222;
$color-transparent: rgba(0, 0, 0, 0);
$color-white: #fff;
$color-white-smoke: #f7f7f7;
$color-outer-space: #434343;
$color-dim-gray: #6b6b6b;
$color-gray: #999;
$color-background-tertiary: #eeeeee;
$color-dark-gray: #a2a2a2;
$color-light-gray: #dcdcdc;
$color-isabelline: #ebebeb;
$color-light-slate-gray: #73809e;
$color-platinum: #e2e2e2;
$color-light-pastel-blue: #f1f4fa;
$color-ucla-blue: #455793;
$color-baby-blue-eyes: #526ab9;
$color-baby-blue-eyes-light: #98b0ff;
$color-pale-aqua: #c9d6ef;
$color-light-pale-aqua: #e7edff;
$color-munsell: #edeff4;
$color-apple-green: #75ca18;
$color-success-green: #4a7915;
