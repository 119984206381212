@import './app.colors.scss';
@import './app.mixins.scss';

* {
  font-family: 'Poppins', sans-serif;
  color: inherit;
  box-sizing: border-box;
  outline: none;

  // Define global scroll behaviors
  // Top margin should be large enough to include all possible header/banner scenarios regardless of context
  scroll-snap-align: start;
  scroll-behavior: smooth;
  scroll-margin-top: 162px;

  @media (max-width: 1200px) {
    scroll-margin-top: 175px;
  }
}

html {
  height: 100%;
}

// RESETS
p {
  //margin: 2px 0 2px 0;
}

body {
  height: 100%;
  margin: 0;
  color: $color-black;
  background: $color-white;

  /* Hide everything which might overflow on the x-axis */
  overflow-x: hidden;

  &.page-signup {
    margin: 0;
    padding: 0;
  }
}

main {
  height: 100%;
}

@media print {
  .noPrint,
  .noPrint * {
    display: none !important;
  }

  // Hide Hubspot chat when printing
  #hubspot-messages-iframe-container div + iframe {
    display: none !important;
    visibility: hidden;
  }

  // Recharts print handling
  // Recalculate width in print mode to fix the bug the graph isn't responsive when print
  .recharts-wrapper,
  .recharts-surface {
    height: calc(100%) !important;
    width: calc(100%) !important;
    margin-top: -5px;
  }

  .recharts-legend-wrapper {
    width: calc(100%) !important;
  }

  // Fix legend item issues due to recalculated width
  .recharts-legend-item > svg {
    width: auto !important;
    display: inline-block !important;
    right: inherit !important; /* align to left */
  }
}

.recharts-brush-traveller {
  stroke-width: 2px;
  stroke-linecap: round;
}

#__next {
}

form {
  width: 100%;
}

a {
  color: $color-ultramarine-blue;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* Global styling of any mark elements to reduce the intensity of the highlight */
mark {
  background-color: rgba(255, 227, 0, 0.2);
}

/* Global offset for anchor targets to include space for headers */
:target {
  overflow: initial !important;
  overflow-y: initial !important;
}
